import React, { useCallback, useMemo, useRef, useState } from 'react'
import {
  Space,
  Button,
  Typography,
  Form,
  Input,
  Card,
  Col,
  Row,
  ConfigProvider,
  Radio,
  message,
  Modal,
  Image,
} from 'antd'
import axios from 'axios'
import { TwitterOutlined, YoutubeFilled, UserOutlined, MailOutlined } from '@ant-design/icons'
import GitHubButton from 'react-github-btn'

import cx from 'classnames'
import 'antd/dist/reset.css'

import logo from './logo.svg'
import logo_color from './logo_color.svg'
import logo_text from './logo_text.svg'
import logo_opendan_ai from './logo_opendan_ai.svg'

import img1 from './assets/1.jpg'
import img2 from './assets/2.jpg'
import img3 from './assets/3.jpg'
import img4 from './assets/4.jpg'
import img5 from './assets/5.jpg'
import img6 from './assets/6.jpg'
import img7 from './assets/7.jpg'

import imgStarGithub from './assets/star_github.jpeg'

import './App.css'

import { env } from './env'

const { Title } = Typography

interface FormValues {
  email: string
  name: string
  role: 1 | 2
}

interface PortalSection {
  title: string
  subtitle: string
  image: string
}

interface PortalData {
  title: string
  subtitle: string
  sections: PortalSection[]
}

function App() {
  const [isModalOpened, setIsModalOpened] = useState(false)
  const anchorTargetElRef = useRef<HTMLDivElement>(null)

  const handleClickGithubButton = useCallback(() => {
    window.open('https://github.com/fiatrete/OpenDAN-Personal-AI-Server-OS', '_blank')
  }, [])

  const handleClickGetEarlyAccessButton = useCallback(() => {
    const anchorTargetEl = anchorTargetElRef.current
    if (anchorTargetEl === null) {
      return
    }
    window.scrollTo({
      top: anchorTargetEl.getBoundingClientRect().top,
      behavior: 'smooth',
    })
  }, [])

  const handleFormFinish = useCallback((values: FormValues) => {
    axios
      .post(`${env.REACT_APP_API_SERVER}/api/waitlist/join`, {
        email: values.email,
        name: values.name,
        job_type: values.role,
        referrer: document.referrer,
      })
      .then((res) => {
        if (res.status === 200) {
          // message.success('Subscribed')
          setIsModalOpened(true)
        } else {
          message.error('Join failed')
        }
      })
      .catch(() => {
        message.error('Join failed')
      })
  }, [])

  const handleGotoGithub = useCallback(() => {
    window.open('https://github.com/fiatrete/OpenDAN-Personal-AI-OS', '_blank')
    setIsModalOpened(false)
  }, [])

  const handleModalClose = useCallback(() => {
    setIsModalOpened(false)
  }, [])

  const data: PortalData = useMemo(() => {
    return {
      title: 'Open-source Private AI OS',
      subtitle: 'Consolidating various AI modules in one place for your private use.',
      sections: [
        {
          title: 'Collaborate various AI models',
          subtitle:
            'One click to install and collaborate various open-source models, instead of being tortured by complex runtime environments.',
          image: img4,
        },
        {
          title: 'AI app-store',
          subtitle: 'Download and run various AI apps, based on collaboration of multiple AI modules.',
          image: img6,
        },
        {
          title: 'Automate your task',
          subtitle: 'Develop your own apps to invoke different AI modules and automate the resolution of tasks.',
          image: img7,
        },
        {
          title: 'Private Freedom',
          subtitle: 'Own your freedom, instead of being controlled by the NSFW label.',
          image: img3,
        },
        {
          title: 'Private data',
          subtitle: 'Own your private data, instead of being taken away.',
          image: img1,
        },
        {
          title: 'Private models',
          subtitle: 'Own your private models, instead of being stolen and misused.',
          image: img2,
        },
        {
          title: 'Share & Earn',
          subtitle:
            'Earn tokens by sharing your AI computing power, instead of being exploited by oligopolistic corporations.',
          image: img5,
        },
      ],
    }
  }, [])

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#00b96b',
        },
      }}
    >
      <div className='App'>
        <header className='header'>
          <section className='nav'>
            <img
              src={logo_opendan_ai}
              className='logo'
              alt='logo'
            />
            <GitHubButton
              href='https://github.com/fiatrete/OpenDAN-Personal-AI-OS'
              data-color-scheme='no-preference: light; light: light; dark: dark;'
              data-size='large'
              data-show-count='true'
              aria-label='Star fiatrete/OpenDAN-Personal-AI-OS on GitHub'
            >
              Star
            </GitHubButton>
          </section>

          <div className='header-form-wrap'>
            <div className='main-intro-wrap'>
              <Space
                className='App-logo-wrap'
                direction='vertical'
                align='center'
              >
                <img
                  src={logo}
                  className='App-logo'
                  alt='logo'
                />
                <img
                  src={logo_text}
                  className='App-logo-text'
                  alt='logo'
                />
              </Space>
              <section className='slogan-wrap'>
                <Space
                  direction='horizontal'
                  align='center'
                  size='large'
                >
                  <div className='slogan'>
                    <Title
                      level={2}
                      className='Title-primary'
                    >
                      {data.title}
                    </Title>
                    <Title
                      level={4}
                      className='Title-secondary'
                    >
                      {data.subtitle}
                    </Title>
                  </div>
                </Space>
              </section>
            </div>
            <div className='early-access-form-wrap'>
              <Form<FormValues>
                name='normal_login'
                className='form'
                onFinish={handleFormFinish}
                initialValues={{ role: 1 }}
              >
                <Form.Item
                  name='email'
                  rules={[{ required: true, message: 'Please input your Email!' }]}
                >
                  <Input
                    prefix={<MailOutlined className='site-form-item-icon' />}
                    type='email'
                    placeholder='Your Email'
                    size='large'
                  />
                </Form.Item>

                <Form.Item
                  name='name'
                  rules={[{ required: true, message: 'Please input your name!' }]}
                >
                  <Input
                    prefix={<UserOutlined className='site-form-item-icon' />}
                    placeholder='Your Name'
                    size='large'
                  />
                </Form.Item>

                <Form.Item
                  name='role'
                  rules={[{ required: true, message: 'Please select your role!' }]}
                >
                  <Radio.Group
                    name='role'
                    style={{ width: '100%' }}
                  >
                    <Row gutter={8}>
                      <Col span={12}>
                        <Card
                          size='small'
                          bodyStyle={{ padding: 8 }}
                        >
                          <Radio value={1}>as Developer</Radio>
                        </Card>
                      </Col>
                      <Col span={12}>
                        <Card
                          size='small'
                          bodyStyle={{ padding: 8 }}
                        >
                          <Radio value={2}>as Investor</Radio>
                        </Card>
                      </Col>
                    </Row>
                  </Radio.Group>
                </Form.Item>

                <Form.Item>
                  <Button
                    type='default'
                    ghost
                    htmlType='submit'
                    className='form-button'
                    size='large'
                  >
                    Join the waitlist
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </header>

        <section className='sub-content-wrap'>
          {data.sections.map((item) => (
            <section className='sub-content'>
              <section className='title'>
                <Title className='Title-primary'>{item.title}</Title>
                <Title
                  level={3}
                  className='Title-secondary'
                >
                  {item.subtitle}
                </Title>
              </section>

              <img
                src={item.image}
                className='img'
                alt=''
              />
            </section>
          ))}
        </section>

        <section className='footer-slogan'>
          <img
            src={logo_color}
            className='App-footer-logo'
            alt='logo'
          />
          <Title className='Title-primary'>
            Empower Open-source AI OS and Apps, uniting minds for a liberal and transparent AI eco-system.
          </Title>
        </section>

        <footer className='footer'>
          <Title
            level={5}
            className='Title-secondary'
          >
            OpenDAN.ai © 2023
          </Title>
          <Space>
            <Button
              type='link'
              href='https://twitter.com/openDAN_AI'
              target='_blank'
              icon={<TwitterOutlined style={{ color: '#fff' }} />}
            />
            <Button
              type='link'
              href='https://www.youtube.com/@opendan_ai'
              target='_blank'
              icon={<YoutubeFilled style={{ color: '#fff' }} />}
            />
          </Space>
        </footer>
      </div>

      <Modal
        open={isModalOpened}
        destroyOnClose={true}
        title=''
        onCancel={handleModalClose}
        footer={null}
        width={600}
        maskClosable={false}
      >
        <div className={cx('mt-6')}>
          <div className={cx('text-3xl font-medium')}>Subscribe successfully</div>
          <Image
            src={imgStarGithub}
            width={'100%'}
            preview={true}
            className={cx('mt-4')}
          />
          <ol className={cx('mt-6 font-medium')}>
            <li>Click the "Star" button on GitHub.</li>
            <li>not miss the latest updates.</li>
            <li>
              recent, <span className={cx('text-red-500')}>OpenDAN AI butler agent Jarvis</span> release on June 5th.
            </li>
          </ol>
          <div className={cx('mt-6 mb-3', 'flex justify-center items-center')}>
            <button
              className={cx(
                'bg-[#ffcc00] hover:bg-[#ffbb00] text-red-500 text-lg font-medium',
                'px-4 py-1.5',
                'border-none rounded-2xl',
                'cursor-pointer',
              )}
              onClick={handleGotoGithub}
            >
              Goto GitHub
            </button>
          </div>
        </div>
      </Modal>
    </ConfigProvider>
  )
}

export default App
