declare global {
  interface Window {
    env: EnvType
  }
}

type EnvType = {
  REACT_APP_API_SERVER: string
}

export const env: EnvType = { ...process.env, ...window.env }
